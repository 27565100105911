import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserServiceInterface } from '@hutsix/ngxh6';

@Injectable({
    providedIn: 'root',
})
export class RedirectGuardService  {
    constructor(private router: Router, @Inject('UserService') public user: UserServiceInterface) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // do some checks here
        return this.user.watch.pipe(
            map(user => {
                if (!user || !user.loggedIn) {
                    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                    return false;
                }

                if (this.user.isGranted('ROLE_STAFF')) {
                    this.router.navigate(['/crm']);
                    return false;
                }

                this.router.navigate(['/account']);
                return false;
            }),
        );
    }
}
