import { Inject, Injectable, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiServiceInterface } from '@hutsix/ngxh6';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EventInterface } from '../../_generated/api_interfaces/api/event.interface';
@Injectable({
    providedIn: 'root',
})
export class SelectedEventService {
    public response$: Observable<ReturnType<EventInterface['apiEventsIdGet']>>;
    public response: ReturnType<EventInterface['apiEventsIdGet']>;
    private eventIdSubject = new BehaviorSubject<string>(null);
    private eventId: string;

    constructor(@Inject('ApiService') private api: ApiServiceInterface, private router: Router, private route: ActivatedRoute) {

    }

    getId(): Observable<string> {
        return this.eventIdSubject.asObservable();
    }

    setId(eventId: string): void {
        // Set the eventId, this used for caching
        this.eventId = eventId;

        // Update the subject, sent to subscribers
        this.eventIdSubject.next(eventId);
    }

    setDefaults(): void {
        // Check if the eventId has been set
        if (this.eventId) {
            this.eventIdSubject.next(this.eventId);
        } else {
            // Set up the observable for getting the current event
            this.response$ = this.api.get({
                url: '/api/v1/events/current',
                displayErrors: false,
            });

            // Subscribe to get the current event, send to subscribers
            this.response$.subscribe({
                next: res => {
                    this.eventIdSubject.next(res.data.id);
                },
                error: () => {
                    // TODO set up proper error handling
                    console.error('Failed to retrieve Id from API endpoint');
                },
            });
        }
    }
}
