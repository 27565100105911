export const environment = {
    production: true,
    env: 'production',
    apiUrl: 'https://api.online.katherineshow.org.au',
    whitelistedDomains: ['api.online.katherineshow.org.au'],
    blacklistedRoutes: [],
    recaptcha_site_key: '6LcFDeYUAAAAACnO-36JVGlpgofBw3B7287rtdDL',
    // Per Stripe documentation
    // https://stripe.com/docs/keys
    // This is OK to publish
    stripePublishableKey: 'pk_live_3yQVX6dDBRYy4NqRrI0B7jED',
};
